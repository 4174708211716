/******************************************************************************\
 * File: Dropdown.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/mapListView/directions/Dropdown.scss';
import appStyle from '@/style/App.scss';
//------------------------------------------------------------------------------
// Components ------------------------------------------------------------------
import Link from '@/components/common/header/Link';
import ArrowLink from '@/components/common/ArrowLink';
import WaypointsList from '@/components/mapListView/directions/WaypointsList';
import ImageCarousel from '@/components/mapListView/directions/ImageCarousel';
import { shortenHtmlString } from '@/helpers/functions';
import OvalIcon from '@/components/common/icons/Oval';
import StartFlag from '@/components/common/icons/StartFlag';
import FinishFlag from '@/components/common/icons/FinishFlag';
import ReturnTrip from '@/components/common/icons/ReturnTrip';
import OneWay from '@/components/common/icons/OneWay';
import Reverse from '@/components/common/icons/Reverse';
import Spinner from '@/components/common/loading/Spinner';
import Tooltip from '@/components/common/form/Tooltip';
import AddMarkerIcon from '@/components/common/icons/AddMarker';
import ClockFill from '@/components/common/icons/ClockFill';
import Speed from '@/components/common/icons/Speed';
import PinStack from '@/components/common/icons/PinStack';
import Toggle from '@/components/common/Toggle';
import AvoidToggle from '@/components/mapListView/AvoidToggle';
import UploadGPXButton from '@/components/mapListView/UploadGPXButton';

import SimpleHtmlRenderer from '@/components/common/SimpleHtmlRenderer';

import Downshift from '@/components/common/search/Downshift';
import DescriptionSidebar from '@/components/mapListView/sidebar/DescriptionSidebar';
import { get as getLocalStore } from '@/helpers/persistor';
import RideEvent from '@/components/common/RideEvent';
import {
    setRideSubTypeForAnalytics,
    setRideTypeForAnalytics,
    analyticsWithData
} from '@/helpers/analytics';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import {
    placeholderForCount,
    prettyTime,
    prettyMiles,
    prettyMPH,
    rideAverageSpeed
} from '@/helpers/functions';
import { WaypointType } from '@/helpers/constants';
import { rideTypeData } from '@/helpers/checkboxes';
import {
    updateSidebar,
    setShowImages,
    setShowImagesSidebar,
    setImageIndex,
    setImages
} from '@/store/map';
import { nearestPointIndex } from '@/helpers/map';
import { openDropdown, isRideRecorded, previewRide } from '@/store/rides';
import {
    reverseWaypoints,
    isRideOneWay,
    toggleOneWay,
    updateRide,
    updateRouteShape,
    editRide
} from '@/store/edit_ride';
import { dealerUrl } from '@/helpers/routes';
import { completeCreateStep } from '@/store/tutorial';
import { getInputState } from '@/store/autocomplete';
import { translate, formatTemp, formatSpeed } from '@/helpers/i18n';
import withRouter from '@/helpers/hooks';

const t = translate('directions.Dropdown');
const tRideType = translate('helpers.checkboxes');

//App context
import AppContext from '@/contexts/AppContext';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Dropdown extends React.Component {
    static Description = ({
        ride,
        props,
        openSidebar,
        showFullDescription,
        setShowDescription,
        setShowImagesSidebar,
        onShowImages,
        setImageIndex,
        imageIndex,
        isCreate
    }) => {
        const hasDealerInfo = !!(
            ride.dealerName || ride.subType === 'HOG_CHAPTER'
        );
        const hasDescription = !!ride.description;

        return (
            <div>
                {(hasDealerInfo || hasDescription) && (
                    <div className={style.description}>
                        {hasDealerInfo && (
                            <Link
                                className={style.dealer}
                                {...(ride.subType === 'EAGLE_RIDER'
                                    ? {
                                          href: 'https://www.harley-davidson.com/us/en/rent-a-bike.html'
                                      }
                                    : {
                                          to: `${dealerUrl(
                                              ride.dealerId
                                          )}?sidebar=1`
                                      })}>
                                {ride.dealerName
                                    ? t('recommendedBy', {
                                          dealer:
                                              ride.recommendedBy ||
                                              ride.dealerName,
                                          t: 'Recommended By: {dealer}'
                                      })
                                    : t('Recommended Ride')}
                            </Link>
                        )}
                        {!!ride && ride.photos && ride.photos.length > 0 && (
                            <ImageCarousel
                                images={ride.photos}
                                setShowImagesSidebar={setShowImagesSidebar}
                                setImageIndex={setImageIndex}
                                imageIndex={imageIndex}
                                slidesPerView={1.4}
                                isHorizontal={true}
                                openSidebar={() => openSidebar('images')}
                                onShowImages={() => onShowImages(true)}
                            />
                        )}
                        {hasDescription &&
                            (showFullDescription ? (
                                <div style={{ paddingTop: '12px' }}>
                                    <DescriptionSidebar
                                        description={ride.description}
                                        className={style.description}
                                        isOpen={showFullDescription}
                                        onClick={setShowDescription}
                                        onRequestClose={setShowDescription}
                                    />
                                </div>
                            ) : (
                                <div
                                    style={{
                                        paddingTop: '12px',
                                        fontSize: '14px'
                                    }}>
                                    <SimpleHtmlRenderer
                                        className={style.description}
                                        html={shortenHtmlString(
                                            ride.description,
                                            300
                                        )}
                                    />{' '}
                                    {ride.description.length > 300 && (
                                        <ArrowLink
                                            onClick={setShowDescription}
                                        />
                                    )}
                                </div>
                            ))}
                    </div>
                )}
                {!!ride && !!ride.event && !isCreate && (
                    <RideEvent event={ride.event} dropdown={true} />
                )}
            </div>
        );
    };

    state = {
        /**
         * because React Beautiful DND doesn't support parent transforms, we
         * manually disable the transform after the animation happens.
         */
        override: false,
        isRideEvent: false,
        showWaypoints: false,
        urlError: false,
        showAvoidPanel: false,
        showDirections: false,
        showFullDescription: false,
        weatherImported: false
    };

    setShowWaypoints = () => {
        const locationsAnalyticsState = !this.state.showWaypoints
            ? 'on'
            : 'off';
        const analyticsValue = 'list waypoints';
        analyticsWithData(analyticsValue, { value: locationsAnalyticsState });
        return this.setState({ showWaypoints: !this.state.showWaypoints });
    };
    setShowAvoidPanel = () =>
        this.setState({ showAvoidPanel: !this.state.showAvoidPanel });
    setShowDescription = () =>
        this.setState({ showFullDescription: !this.state.showFullDescription });

    fetchWeather = ({ weatherData }) => {
        this.setState({ weatherImported: true });
        if (!weatherData) return;
        const [startWeather, endWeather] = weatherData;
        this.setState({ startWeather, endWeather });
    };

    componentDidMount = () => {
        if (this.props.isOpen) this.setState({ override: true });
    };

    componentDidUpdate = (prevProps, prevState) => {
        const { isOpen } = this.props;

        if (prevProps.isOpen !== isOpen) {
            // Refactor the logic of this setState
            this.setState({ override: false });
            if (isOpen) {
                // Refactor the logic of this setState
                setTimeout(
                    () => this && this.setState({ override: true }),
                    200
                );
            }
        }

        const { ride } = this.props;
        const { ride: prevRide } = prevProps;
        const rideIdChanged = ride.id !== prevRide.id || !ride.id;
        const rideOffRoadChanged =
            ride.offRoad !== prevRide.offRoad || !ride.offRoad;
        const { weatherImported } = prevState;

        if (!weatherImported) {
            if (
                isRideRecorded(ride) &&
                !!ride &&
                !!ride.session &&
                !!ride.session.createdTime
            ) {
                this.fetchWeather(ride);
            }
        }
        if (rideIdChanged || rideOffRoadChanged) {
            if (!window.hd) window.hd = {};
            if (!window.hd.analytics) window.hd.analytics = {};
            window.hd.analytics.off_road = ride.offRoad || false;
            setRideSubTypeForAnalytics(ride);
            setRideTypeForAnalytics(ride);
            if (rideOffRoadChanged) {
                if (ride.offRoad)
                    window.hd.analytics['route type'] = 'off road';
                if (!ride.offRoad)
                    window.hd.analytics['route type'] = 'motorcycle';
            }
        }
    };

    renderTransportationMode(isCreate, isBicycleRoute, onChangeTransportMode) {
        return (
            <div className={style.borderBottom}>
                <span className={style.routeType}>
                    <span
                        style={{
                            flex: '2 0 0',
                            paddingTop: '12px',
                            textTransform: 'uppercase'
                        }}>
                        {t('Route type')}
                    </span>
                    <span
                        className={
                            !isBicycleRoute
                                ? style.activeIcon
                                : style.inactiveIcon
                        }>
                        <Tooltip
                            value={t('Motorcycle Routing')}
                            placement="s"
                            noDelay>
                            {isCreate ? (
                                <a onClick={() => onChangeTransportMode(null)}>
                                    <img src={'/img/icon-motorcycle.svg'} />
                                </a>
                            ) : (
                                <img src={'/img/icon-motorcycle.svg'} />
                            )}
                        </Tooltip>
                    </span>
                    <span
                        className={
                            isBicycleRoute
                                ? style.activeIcon
                                : style.inactiveIcon
                        }>
                        <Tooltip
                            value={t('eBicycle Routing')}
                            placement="s"
                            noDelay>
                            {isCreate ? (
                                <a
                                    onClick={() =>
                                        onChangeTransportMode('BICYCLE')
                                    }>
                                    <img src={'/img/icon-ebike.svg'} />
                                </a>
                            ) : (
                                <img src={'/img/icon-ebike.svg'} />
                            )}
                        </Tooltip>
                    </span>
                </span>
            </div>
        );
    }

    renderTabs() {
        const {
            ride,
            currentRoutePoint,
            editable,
            editRide,
            meta,
            previewGPXRide,
            updateUploadModal,
            isCreate
        } = this.props;

        const { showWaypoints } = this.state;
        const { points, legs, waypoints, offRoad } = ride;
        const hasWaypoints = !!waypoints
            ? waypoints.findIndex((waypoint) => waypoint.type === 'WAYPOINT')
            : 1;
        const showTimeAndDistance =
            !showWaypoints || (showWaypoints && hasWaypoints == -1)
                ? true
                : false;

        // const isBicycleRoute = transportMode === 'BICYCLE';
        const isOffRoadRoute = offRoad;
        const isRecordedRide = (waypoints || {}).length > 1;

        // // Locations are all waypoints without type WAYPOINT
        const locations = (waypoints || []).filter(
            (waypoint) => waypoint.type !== WaypointType.WAYPOINT
        );

        const loadingWaypoints = !ride.waypoints;
        const isPreview = () => window.location.pathname.includes('/preview');

        const waypointIndex = nearestPointIndex(
            points,
            this.state.showWaypoints ? waypoints : locations,
            currentRoutePoint
        );

        return (
            <div
                className={classNames(style.inner, {
                    [style.recorded]: isRecordedRide,
                    [style.offroad]: isOffRoadRoute,
                    [style.preview]: isPreview
                })}
                style={{ flex: 2 }}>
                <div className={style.scroll}>
                    {loadingWaypoints ? (
                        <div className={style.loading}>
                            <Spinner />
                        </div>
                    ) : (
                        <WaypointsList
                            highlightIndex={waypointIndex}
                            editable={!isOffRoadRoute && editable}
                            waypoints={
                                this.state.showWaypoints ? waypoints : locations
                            }
                            legs={legs}
                            showTimeAndDistance={showTimeAndDistance}
                        />
                    )}
                </div>
                {isCreate &&
                    isOffRoadRoute &&
                    waypoints &&
                    !waypoints.length && (
                        <div>
                            <span className={style.gpxImportText}>
                                {t(
                                    'Click on the map to start creating your ride.'
                                )}
                            </span>
                        </div>
                    )}
                <div>
                    {isCreate && !isOffRoadRoute && (
                        <div className={style.upDownshift}>
                            {editable && this.PresentDownShift(locations)}
                        </div>
                    )}
                    {waypoints && !waypoints.length && (
                        <div>
                            <div className={style.createAlt}>{t('Or')}</div>
                            <div className={style.gpxImport}>
                                <span className={style.gpxImportText}>
                                    {t('Create ride from file')}
                                </span>
                                <span className={style.gpxImportButton}>
                                    <UploadGPXButton
                                        ride={ride}
                                        meta={meta}
                                        updateUploadModal={updateUploadModal}
                                        disabled={
                                            waypoints.length > 0 ||
                                            (ride || {}).shortId
                                        }
                                        editRide={editRide}
                                        previewGPXRide={previewGPXRide}
                                        position={'s'}
                                    />
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    weatherDetails({ iconNumber, temperature, windSpeed }) {
        return (
            <div>
                <img
                    src={`/img/accuweather/${iconNumber}.png`}
                    className={style.weatherDetails}
                />
                <span className={style.weatherDetailsText}>
                    {Math.round(formatTemp(temperature))}°
                </span>
                <img
                    src="/img/accuweather/32.png"
                    className={style.weatherDetails}
                />
                <span className={style.weatherDetailsText}>
                    {formatSpeed(windSpeed)}
                </span>
            </div>
        );
    }
    renderDetails() {
        const { ride, canEdit } = this.props;

        const { waypoints, offRoad, recordId: isRecordedRide } = ride;
        const session = ride.session || {};
        const showBikeDetails =
            !session.notOnABike && (session.isARental || session.bikeMake);
        const { endTime, startWeather, endWeather } = this.state;

        return (
            <div
                className={classNames(style.inner, {
                    [style.recorded]: isRecordedRide,
                    [style.offroad]: offRoad
                })}>
                <div
                    className={classNames(style.scroll, {
                        [style.recorded]: isRecordedRide
                    })}>
                    <div>
                        {!!ride &&
                            !!ride.subType &&
                            this.rideTypeDetail(ride, canEdit)}
                        <p className={style.rideFinished}>
                            {t('Ride Finished:')}&nbsp;{endTime}
                        </p>
                        {!isRecordedRide && !offRoad && (
                            <div className={style.icons}>
                                <div className={style.icon}>
                                    <ClockFill />
                                    <span>{prettyTime(ride.duration)}</span>
                                </div>
                                <div className={style.icon}>
                                    <PinStack />
                                    <span>{prettyMiles(ride.length)}</span>
                                </div>
                                <div className={style.icon}>
                                    <Speed />
                                    <span>
                                        {prettyMPH(rideAverageSpeed(ride))}
                                    </span>
                                </div>
                            </div>
                        )}
                        {isRecordedRide &&
                            ride.description &&
                            this.detailText(ride.description)}

                        {isRecordedRide &&
                            waypoints &&
                            waypoints.length > 0 && (
                                <div className={style.waypoints}>
                                    <div className={style.waypoint}>
                                        {this.squiggle}
                                        <div>
                                            <StartFlag
                                                className={style.startFlag}
                                            />
                                            <div>
                                                {waypoints[0].address ||
                                                    `${waypoints[0].latitude}, ${waypoints[0].longitude}`}
                                            </div>
                                            {startWeather &&
                                                this.weatherDetails(
                                                    startWeather
                                                )}
                                        </div>
                                    </div>
                                    <div className={style.waypoint}>
                                        <FinishFlag
                                            className={style.startFlag}
                                        />
                                        <div>
                                            {waypoints[waypoints.length - 1]
                                                .address ||
                                                `${
                                                    waypoints[
                                                        waypoints.length - 1
                                                    ].latitude
                                                }, ${
                                                    waypoints[
                                                        waypoints.length - 1
                                                    ].longitude
                                                }`}
                                        </div>
                                        {endWeather &&
                                            this.weatherDetails(endWeather)}
                                    </div>
                                </div>
                            )}
                        {showBikeDetails && this.detailHeader('Bike Used')}
                        {showBikeDetails &&
                            this.detailText(this.bikeDetail(ride))}
                    </div>
                </div>
            </div>
        );
    }

    rideTypeDetail = (ride, canEdit, shortRideType = false) => {
        const { type } = ride;
        const isCuratedRide = type == 'CURATED';
        const cn = classNames();
        const rideType =
            !!isCuratedRide || !canEdit
                ? tRideType('Recommended')
                : tRideType('My Ride');
        let rideSubType = rideTypeData().find((t) => t.value === ride.subType);
        if (rideSubType == undefined) {
            rideSubType = rideTypeData().find((t) => t.value === 'HD');
        }
        const { offRoad } = ride;

        return offRoad ? (
            <div
                className={classNames(style.rideTypeDetail, {
                    [style.shortRideType]: !!shortRideType
                })}>
                <img src="/img/icon-helmet.svg" />
                {rideType} • {rideSubType.label} •
                <img src="/img/icon-off-road.svg" />
            </div>
        ) : (
            <div
                className={classNames(style.rideTypeDetail, {
                    [style.shortRideType]: !!shortRideType
                })}>
                <img src="/img/icon-helmet.svg" />
                {rideType} • {rideSubType.label}
            </div>
        );
    };

    detailHeader = (text) => (
        <div className={style.rideDetails}>
            {/* <PencilIcon className={style.editIcon} /> */}
            <span className={style.detailHeader}>{text}</span>
        </div>
    );

    detailText = (text) => <div className={style.detailText}>{text}</div>;

    bikeDetailDisplay = (text, upcase = true) => {
        const styledText = upcase ? text.toUpperCase() : text;
        return <div dangerouslySetInnerHTML={{ __html: styledText }} />;
    };

    getHDModelForBike = (hdModelNames, bike) =>
        !!hdModelNames && !!hdModelNames[bike.bikeYear]
            ? (
                  hdModelNames[bike.bikeYear].find(
                      (item) => item.mc === bike.bikeModel
                  ) || {}
              ).n
            : false;

    getHDModelNameForBike = (bike) => {
        const hdModelNames = (getLocalStore('hdModelNames') || {}).data;
        return this.getHDModelForBike(hdModelNames, bike);
    };

    bikeDetail = (ride) => {
        const bike = ride.session;
        if (!!bike.bikeName) return this.bikeDetailDisplay(bike.bikeName);
        if (bike.isARental)
            return this.bikeDetailDisplay('I was on a rental bike', false);
        if (bike.notOnABike)
            return this.bikeDetailDisplay('I was not on a bike', false);
        const hdModelNameForBike = this.getHDModelNameForBike(bike);
        return !!hdModelNameForBike
            ? this.bikeDetailDisplay(
                  `${bike.bikeYear} Harley-Davi dson ${hdModelNameForBike}`
              )
            : this.bikeDetailDisplay(
                  `${bike.bikeYear} ${bike.bikeMake} ${bike.bikeModel}`
              );
    };

    squiggle = (
        <svg
            className={style.squiggle}
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="81"
            viewBox="0 0 13 81">
            <path
                fill="none"
                stroke="#FFF"
                strokeLinecap="round"
                d="M6.5 0c-7.333 15.951-7.333 29.636 0 41.055S13.833 65.789 6.5 81"
            />
        </svg>
    );

    Locations = (showWaypoints, setShowWaypoints) => (
        <div className={style.locations}>
            <div className={style.locationsIcon}>
                <span>
                    <OvalIcon />
                    <span
                        className={style.locationText}
                        id="locations"
                        data-testid="locations">
                        {t('Locations')}
                    </span>
                </span>
                <span className={style.locationToggle}>
                    <Toggle
                        text={t('List Waypoints')}
                        className={style.toggleWaypoints}
                        value={showWaypoints}
                        onClick={setShowWaypoints}
                        id="waypoints-toggle"
                    />
                </span>
            </div>
        </div>
    );

    PresentDownShift = (locations) => {
        return (
            <Downshift
                className={style.search}
                value={this.state.value}
                onSubmit={() => this.setState({ value: '' })}
                icon={
                    [StartFlag, FinishFlag][locations.length] || AddMarkerIcon
                }
                placeholder={placeholderForCount(locations.length)}
                hasLocations={locations.length > 0}
            />
        );
    };

    render() {
        const {
            isOpen,
            toggleOneWay,
            reverseWaypoints,
            ride,
            isCreate,
            updateRide,
            canEdit,
            oneWay,
            openSidebar,
            setShowImagesSidebar,
            onShowImages,
            imageIndex
        } = this.props;
        const { waypoints, leg, offRoad } = ride;
        const {
            override,
            isRideEvent,
            urlError,
            showAvoidPanel,
            showDirections,
            showFullDescription
        } = this.state;

        const isPreview = () => window.location.pathname.includes('/preview');
        const isOffRoadRoute = offRoad;

        const cn = classNames(style.Dropdown, appStyle.rideDirectionsDropdown, {
            [style['expanded']]: isOpen,
            [style['override-transform']]: override,
            [style.directionsActive]: showDirections
            // [style.isDealer]: addDealerFunc
        });
        const onToggleRideEvent = () => {
            if (!!isRideEvent) updateRide('event', null);
            updateRide('hasEvent', !isRideEvent);
            this.setState({ isRideEvent: !isRideEvent });
        };
        const onToggleRideEventUrlError = (val) => {
            this.setState({ urlError: val });
        };

        const isSharedRide = window.location.pathname.includes('/share');
        const isCreatePreview =
            window.location.pathname.includes('/create/preview');
        const showAvoid =
            !isOffRoadRoute &&
            (canEdit || isCreate || isSharedRide || isCreatePreview);

        return (
            <div className={cn}>
                <div
                    className={classNames(style.rideDetail, {
                        [style.recorded]: isRideRecorded(ride),
                        [style.preview]: isPreview()
                    })}>
                    {!isCreate &&
                        !isRideRecorded(ride) &&
                        !!ride &&
                        !!ride.subType &&
                        this.rideTypeDetail(ride, canEdit, true)}
                </div>
                {!isRideRecorded(ride) && (
                    <Dropdown.Description
                        ride={ride}
                        props={this.props}
                        isRideEvent={isRideEvent}
                        onToggleRideEvent={onToggleRideEvent}
                        isCreate={isCreate}
                        onToggleRideEventUrlError={onToggleRideEventUrlError}
                        urlError={urlError}
                        openSidebar={openSidebar}
                        showFullDescription={showFullDescription}
                        onShowImages={onShowImages}
                        setShowDescription={this.setShowDescription}
                        setShowImagesSidebar={setShowImagesSidebar}
                        setImageIndex={setImageIndex}
                        imageIndex={imageIndex}
                        setImages={setImages}
                    />
                )}
                {!isRideRecorded(ride) &&
                    this.Locations(
                        this.state.showWaypoints,
                        this.setShowWaypoints
                    )}
                {isRideRecorded(ride)
                    ? this.renderDetails()
                    : this.renderTabs()}

                {!isRideRecorded(ride) && (
                    <div className={style.footerActions}>
                        {showAvoid && (
                            <div className={style.avoidToolTip}>
                                <AvoidToggle
                                    tooltipClassName={style.avoidToolTip}
                                    showOptions={showAvoidPanel}
                                    setShowOptions={this.setShowAvoidPanel}
                                    isCreate={isCreate || isCreatePreview}
                                />
                            </div>
                        )}
                        {isCreate && (
                            <div className={style.footerContainer}>
                                <div className={style.footer}>
                                    <Link
                                        id={oneWay ? 'round' : 'one-way'}
                                        data-testid={
                                            oneWay ? 'round' : 'one-way'
                                        }
                                        className={style.print}
                                        onClick={toggleOneWay}>
                                        {oneWay ? <ReturnTrip /> : <OneWay />}
                                        {oneWay
                                            ? t('Round Trip')
                                            : t('One Way')}
                                    </Link>
                                    <Link
                                        id="reverse"
                                        data-testid="reverse"
                                        className={style.print}
                                        onClick={reverseWaypoints}>
                                        <Reverse />
                                        {t('Reverse')}
                                    </Link>
                                </div>
                            </div>
                        )}
                    </div>
                )}

                {isRideRecorded(ride) && (
                    <div className={style.footerActions}>
                        {showAvoid && (
                            <div className={style.avoidToolTip}>
                                <AvoidToggle
                                    tooltipClassName={style.avoidToolTip}
                                    showOptions={showAvoidPanel}
                                    setShowOptions={this.setShowAvoidPanel}
                                    isCreate={!isCreate || isCreatePreview}
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}
//------------------------------------------------------------------------------
// Redux State -----------------------------------------------------------------
const mapStateToProps = (state, ownProps) => {
    const { currentRoutePoint, currentRouteProgress } = state.rides.preview;
    return {
        meta: state.edit_ride.present.meta,
        imageIndex: state.map.imageIndex,
        autocomplete: getInputState(state),
        currentRoutePoint,
        currentRouteProgress,
        oneWay: isRideOneWay(state),
        user: state.user
    };
};
//------------------------------------------------------------------------------
// Redux Actions ---------------------------------------------------------------
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        closeTooltip: () => dispatch(completeCreateStep('search')),
        editRide: (...props) => dispatch(editRide(...props)),
        updateRide: (field, value) => {
            dispatch(updateRide(field, value));
        },
        onChangeTransportMode: (ride, mode) => {
            dispatch(updateRide('transportMode', mode));
            dispatch(updateRouteShape(null, { isBike: !!mode }));
        },
        onChangeMapMode: (mode) => {
            dispatch(updateRide('offRoad', mode));
            dispatch(updateRouteShape(null, { isOffroad: !!mode }));
        },
        previewGPXRide: (ride) => dispatch(previewRide(ride)),
        reverseWaypoints: () => dispatch(reverseWaypoints()),
        toggleOneWay: () => dispatch(toggleOneWay()),
        onToggleDropdown: (value) => dispatch(openDropdown(value)),
        setShowImagesSidebar: (index, images) => {
            dispatch(setImageIndex(index));
            dispatch(setImages(images));
            dispatch(setShowImagesSidebar(true));
        },
        openSidebar: (value, data = null) =>
            dispatch(updateSidebar(value, data)),
        onShowImages: () => dispatch(setShowImages(true))
    };
};

Dropdown.contextType = AppContext;
//------------------------------------------------------------------------------
// Redux Connect ---------------------------------------------------------------
const container = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Dropdown)
);
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default container;
